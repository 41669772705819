import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from "nprogress";
import "nprogress/nprogress";

Vue.use(VueRouter);

NProgress.configure({
  template: `<div class="bar" role="bar" style="background:#29B6F6;"><div class="peg"></div></div>
	  <div class="spinner" role="spinner"><div class="spinner-icon"></div></div>`,
  showSpinner: false,
});

const routes = [
  {
    path: "/:slug",
    name: "login",
    meta: {
      guardIsLocked: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login.vue"),
  },
  {
    path: "/:state/:slug",
    name: "login2",
    meta: {
      guardIsLocked: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login.vue"),
  },
  {
    path: "/",
    name: "main",
    meta: {
      guardIsLocked: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Main.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const doBeforeEach = async (to, from, next) => {
  if (to.path === "/dpp-form" || to.path === "/file-upload") {
    if (localStorage.set == undefined || localStorage.set == "NaN")
      next({
        path: "/",
      });
    else next();
  } else {
    if (localStorage.id) next("/dpp-form");
    else next();
  }
};

router.beforeEach(doBeforeEach);
router.afterEach(() => {
  NProgress.done();
});

export default router;
