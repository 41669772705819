import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
//import cookies from '@/utils/cookies';
import router from '../router';
// import createPersistedState from "vuex-persistedstate";
// import SecureLS from "secure-ls";

// var ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    applicant: {},
    stepper_position: 0,
    applicant_id: localStorage.id || null,
    slug: localStorage.set,
    token: null,
  },
  // plugins: [
  //   createPersistedState({
  //     storage: {
  //       getItem: (key) => ls.get(key),
  //       setItem: (key, value) => ls.set(key, value),
  //       removeItem: (key) => ls.remove(key),
  //     },
  //   }),
  // ],
  mutations: {
    setApplicant(state, payload) {
      state.applicant = payload;
    },
    setStepperPosition(state, payload) {
      state.stepper_position = payload;
    },
    setApplicantId(state, payload) {
      state.applicantId = payload;
    },
    setToken(state, token) {
      state.token = token
    }
  },
  actions: {
    setApplicant({ commit }, payload) {
      commit("setApplicant", payload);
    },

    setStepperPosition({ commit }, payload) {
      commit("setStepperPosition", payload);
    },

    async checkToken() {
      return await new Promise((resolve, reject) => {
        axios.get(
          process.env.VUE_APP_API_URL + 'applicants/checkToken',
          {
            withCredentials: true,
          }
          //{headers : {'Authorization' : 'Bearer' + this.getters.token}}
        ).then(response => {
          const data = response.data.data
          localStorage.id = data.applicant_id

          resolve(response)
        }).catch(error => {
          if (error.response.status === 401) {
            this.dispatch('logout');
            console.log("masuk request")
            router.push({
              path : `/${localStorage.set}`
            })
          }
          reject(error)
        })
      })
    },

    async userLoggedForm(context, payload) {
      return await new Promise((resolve, reject) => {
        axios
          .get(
            process.env.VUE_APP_API_URL + "applicants/" + payload + "/locked",
            {
              withCredentials: true,
            }
            //{headers : {'Authorization' : 'Bearer' + this.getters.token}}
          )
          .then((response) => {
            const data = response.data.data;
            context.commit("setApplicant", data);

            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              this.dispatch('logout');
              console.log("masuk request")
              router.push({
                path : `/${localStorage.set}`
              })
            }
            reject(error);
          });
      });
    },

    setApplicantId({ commit }, payload) {
      commit("setApplicantId", payload);
    },

    handleLogin({ commit }, { recruitment_group_id, no_ktp}) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_API_URL + "applicant-gateway", {
            code: 'A4',
            recruitment_group_id: recruitment_group_id,
            no_ktp: no_ktp,
            // email: this.email
          },
          {
            withCredentials: true,
          })
          .then((response) => {
            //const token = response.data.token.token;
            //console.log(response.headers);

            commit('setToken', null);
            //cookies.setToken(token);
            //console.log(this.getters.token);
            resolve(response.data);
          })
          .catch((err) => {
            reject(err);
          });
      })
    },
    logout(context) {
      context.commit('setToken', null)
      context.commit('setApplicant', {})
      context.commit('setApplicantId', null)
      context.commit('setStepperPosition', 0)
      localStorage.removeItem('id')
      //localStorage.removeItem('vuex')
      //cookies.setToken(null)
      //cookies.removeToken()
    },
  },
  getters: {
    applicant: (state) => state.applicant,
    stepper_position: (state) => state.stepper_position,
    applicant_id: (state) => state.applicant_id,
    slug: (state) => state.slug,
    isLoggedIn: (state) => state.applicant_id !== null,
    token: (state) => state.token,
  },
});
